import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/Seo"
import "./error.css"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="wrapper">
      <div className="errorMsg">
        <h1 className="imFell">hire</h1>
        <h1 className="fugazOne">señor</h1>
        <h1 className="playfair">for</h1>
        <h1 className="openSansCon">junior</h1>
      </div>

      <Link to="/">
        <p className="back"> ¡bye! </p>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
